import React, { useEffect } from "react";
import { styles } from "../styles";
import "../index.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Logo from "../components/Logo.js";

const Anbauvereinigung = () => {
  const isScreenSmall = useMediaQuery("(max-width:750px) and (min-width: 0px)");
  const navigate = useNavigate();

  const [value, setValue] = React.useState("1");
  const minWindoSize = 500;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function NavigateToBecomeMember(e) {
    e.preventDefault();
    navigate("/Mitgliedwerden");
  }

  const bull = (
    <Box component="span" sx={{ mx: "2px", transform: "scale(0.8)" }}>
      •
    </Box>
  );

  return (
    <HelmetProvider>
      <div className="page-home">
        <Helmet>
          <title>Vereinsprinzip Hemp Heaven Hannover</title>
          <meta
            name="description"
            content="Informiere dich über die Anbauvereinigung Hemp Heaven Hannover und entscheide ob du interesse an unserem organischen Cannabis hast, profitiere von hervorragendem Know-How, tausche dich mit Mitgliedern über die vielfalt von Cannabis aus oder lehn dich einfach zurück und lass uns für dich die feinsten Blüten heranwachsen."
          />
          <meta
            name="keywords"
            content="Anbauvereinigung, Hannover, Cannabis, CSC, Cannabis Social Club, Marihuana, Hash, Haschisch, Dope, Weed"
          />
        </Helmet>

        <Box
          component={Paper}
          sx={{
            maxWidth: 800,
            minWidth: 300,
            marginTop: 5,
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Logo />

          {/* <Text style={styles.titleText}>Hanffarm AV Hannover {"\n"}</Text> */}
          {/*                 <div style={{ textAlign: "center" }}>
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Momentan nehmen wir keine neuen Mitglieder auf. Ihr könnt
                      euch dennoch auf dieser Website registrieren und werdet
                      dadurch auf eine Warteliste aufgenommen.
                    </span>
                  </div>
                  {"\n"}
                  {"\n"} */}
          <h1
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Anbauvereinigung Hemp Heaven Hannover
          </h1>
          <p style={{ ...styles.normalText }}>
            Der Hemp Heaven Hannover e.V. ist eine am 13. April 2024 gegründete
            Anbauvereinigung für Genussmittelcannabis und ist damit eine der
            ersten Anbauvereinigungen in Hannover. Das Ziel des Vereins ist es,
            für seine Mitglieder qualitätsgesichertes Cannabis zu Genusszwecken
            anzubauen und Vermehrungsmaterial bereitzustellen. Ein Mitglied kann
            sich für zwei der nachfolgend aufgeführten Pflegemodelle
            entscheiden. {"\n"}
          </p>
          <p style={{ ...styles.boldText, marginBottom: 0 }}>
            Modell Vereinspflege:
          </p>
          <p style={{ ...styles.normalText, marginTop: 3 }}>
            {" "}
            Bei der Vereinspflege werden für jedes Mitglied drei
            Cannabispflanzen gepflanzt, die im Verlauf vom Verein herangezogen
            und gepflegt werden. Nach circa vier Monaten sind die Blüten der
            Cannabispflanze konsumbereit und können vom Mitglied im Vereinsheim
            abgeholt werden. Dieser Zyklus wiederholt sich bis zum Austritt des
            Mitglieds. Das Cannabis wird unter kontrollierten Bedingungen
            gezüchtet und Qualitätskontrollen unterzogen.
            Vereinspflegemitglieder geben hierzu ihren Monatsbedarf an und
            zahlen einen monatlichen Mitgliedsbeitrag, näheres dazu findest du
            im Reiter{" "}
            <a title="Beitragsordnung" href="/Beitragsordnung">
              Beitragsordnung
            </a>{" "}
            . {"\n"}
          </p>
          <p style={{ ...styles.boldText, marginBottom: 0 }}>
            Modell Privatpflege:
          </p>
          <p style={{ ...styles.normalText, marginTop: 3 }}>
            {" "}
            Bei der Privatpflege ist der Verein nur für die Beschaffung von
            Vermehrungsmaterial zuständig, alles Andere muss das Mitglied selbst
            erledigen, die Pflanzenzucht findet nicht im befriedeten Besitztum
            der Anbauvereinigung statt. Privatpflegemitglieder können, falls der
            Bestand des Vereins es zulässt, Cannabis im Vereinsheim durch einen
            erhöhten Monatsbeitrag (Sonderbeitrag, siehe hierzu ebenfalls unter
            dem Reiter{" "}
            <a title="Beitragsordnung" href="/Beitragsordnung">
              Beitragsordnung
            </a>{" "}
            für weitere Informationen) erhalten. {"\n"}
          </p>
          <p style={{ ...styles.normalText }}>
            Für alle Mitglieder steht der Verein jederzeit beratend zu
            problematischem Konsumverhalten, auftretenden Nebenwirkungen und
            Fragen bezüglich der Pflanzenzucht zur Seite.{" "}
          </p>
          {"\n"}
          {"\n"}
          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Auswahl der Cannabissorten
          </h2>
          <p style={styles.normalText}>
            In den ersten Jahren werden die angebotenen Cannabissorten rotieren.
            Diese Entscheidung beruht auf der Tatsache, dass die meisten
            Personen aufgrund der bisherigen Cannabis-Politik keine Erfahrung
            haben, welche Unterschiede zwischen den Sorten vorliegen. Dafür
            werden für jeden Wachstumszyklus drei Cannabissorten mit Hilfe einer
            Sortenumfrage unter den Mitgliedern ausgewählt. Bei der Auswahl wird
            jeweils eine Sativa, eine Indica und eine Hybrid-Sorte ausgesucht.
            Jedes Mitglied kann sich über die Umfragen auf dieser Website an der
            Auswahl beteiligen. Die Sorten, welche zur Auswahl stehen, findest
            du{" "}
            <a title="Pflanzen-Tabelle" href="/plantsdata">
              hier
            </a>{" "}
            oder wenn du in dem Auswahlmenü in der Navigationsleiste auf das
            Sorten-Tabelle klickst. Später soll es jedem Mitglied ermöglicht
            werden, seine Lieblingssorte für jeden Zyklus selbst aufzuwählen.
          </p>
          {"\n"}
          {"\n"}
          <p style={styles.normalText}>
            Falls du dich nicht für die Pflanzenzucht interessierst und damit
            auch nichts zu tun haben möchtest, ist das kein Problem. Der Verein
            kümmert sich vollumfänglich um die Pflanzen, die bei uns gezüchtet
            werden. Du musst lediglich das Blütenmaterial im Vereinsheim
            abholen. Die Pflanzen in unserem Verein werden in einem organischem
            Medium einer Mischung aus Pflanzenerde, Kokosfasern und
            Mikroorganismen gezüchtet. Schädlingsbekämpfung findet
            ausschließlich ohne Chemikalien statt.
          </p>
          {"\n"}
          {"\n"}
          <p style={styles.boldText}>
            Die Anzahl der Mitglieder ist aufgrund der uns zur Verfügung
            stehenden Anbaufläche zum aktuellen Zeitpunkt auf etwa 50 Mitglieder
            begrenzt. Zum jetzigen Zeitpunkt kann sich noch jeder registrieren,
            über die Aufnahme der Mitglieder wird nach einer
            Anmeldungsveranstaltung entschieden.
          </p>
          <Button
            className="signin"
            variant="contained"
            color="success"
            align-items="flex-start"
            onClick={NavigateToBecomeMember}
            sx={{
              marginRight: "auto",
              "@media screen and (max-width: 380px)": {
                // Hier beginnt die Media Query für Bildschirme mit einer Breite von 600px oder weniger
                fontSize: 10,
                // Fügen Sie hier weitere Stile hinzu, um die Tabelle zu verkleinern
              },
            }}
          >
            Mehr über die Anmeldung erfahren
          </Button>
          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Workshops
          </h2>
          <p style={styles.normalText}>
            Als Cannabis-Anbauvereinigung ist es uns wichtig, die Kompetenz
            unserer Mitglieder im Umgang mit Cannabis zu steigern. Diese Ziel
            möchten wir mit Workshops erreichen, bei denen in kleinen Gruppen
            wissen über unsere Lieblingspflanze vermittelt wird.
          </p>
          <p style={styles.boldText}>
            Themen für Workshops sind:
            <br />
          </p>

          <p style={styles.normalText}>
            {bull} Anbau von Cannabis vom Samen bis zur Ernte und Clonen.
            <br />
            {bull} Terpene und Terpen-Profil von Cannabis-Sorten.
            <br />
            {bull} Wirkung, Nebenwirkungen und Wechselwirkungen von Cannabis.
            <br />
            {bull} Verantwortungsvoller Umgang mit Cannabis.
            <br />
          </p>

          <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Anbauvereinigung oder CSC?
          </h2>
          <p style={styles.normalText}>
            Eine Anbauvereinigung unterscheidet sich von Cannabis Social Clubs
            (CSCs) in dem Punkt, dass in Anbauvereinigungen lediglich der Anbau
            der Pflanzen stattfindet. Wenn du auf der Suche nach Veranstaltungen
            bist, in denen ein sozialer Aspekt im Zusammenhang mit Cannabis im
            Mittelpunkt steht, empfehlen wir dir einen CSC aufzusuchen. Wenn der
            CSC in seiner Satzung nicht bestrebt ist einen gemeinschaftlichen
            Anbau zu organisieren, dann ist eine Mitgliedschaft in einem CSC und
            einer Anbauvereinigung möglich.{" "}
          </p>
          <p style={styles.boldText}>
            Falls du eine Person bist, die sich für die Pflanzenzucht
            interessiert, austauschen möchte oder einfach nur gerne für dich
            privat Cannabis konsumiert, bist du hier genau richtig!{" "}
          </p>
          <p style={styles.normalText}>
            Es wird geplant, im späteren Vereinsbestehen den Zutritt zur
            Anbaufläche für Mitglieder zu ermöglichen. Aus Sicherheitsgründen
            und zur Erstellung von standartisierten Prozessen wird die
            Pflanzenzucht zunächst ausschließlich von den Gründungsmitgliedern
            durchgeführt.
          </p>
          {/* <h2
            style={{
              whiteSpace: "pre-line",
              fontSize: window.innerWidth < minWindoSize ? "30px" : "40px",
            }}
          >
            Der Vorstand
          </h2>

          <p style={styles.normalText}>
            Der Vorstand besteht derzeit aus einer Person: Hannes Lokatis. Ich
            bin Zerspanungsmechaniker für Frästechnik, Maschinenbauingenieur für
            Konstruktion und Entwicklung und verfüge über berufliche Erfahrungen
            als Prüfingenieur, Software-Testmanager und Prozessingenieur. Ich
            habe das gesamte Konzept der Anbauvereinigung mit Hilfe meiner
            Freunde und Familie konzipiert, einen Businessplan erstellt,
            Kalkulationen durchgeführt, mich um die Beschaffung der Immobilien
            gekümmert, die Mitglieder- und Produktions-Websites erstellt und bin
            als Fördermitglied für die Cannabis-Anbauvereinigungen Deutschlands
            (CAD) tätig. Als Fördermitglied der CAD habe ich mich an der
            Erstellung eines Anbauvereinigungs-Kalkulationstools sowie eines
            allumfassenden Gesundheits- und Jugendschutzkonzepts beteiligt. Als
            langjähriger Cannabis-Konsument freue ich mich, endlich die Vielfalt
            der Cannabis-Pflanze in Geschmack und Effekt probieren zu können.
            Ich betrachte Cannabis als ein Genussmittel, ähnlich wie einen guten
            Whiskey oder eine Zigarre, das nur zu bestimmten Anlässen genossen
            werden sollte. Als Schlüsselfigur des Vereins schätze ich jedes
            Mitglied und das mir entgegengebrachte Vertrauen und werde alles
            dafür tun, um bestmögliche Qualität und das Wohlbefinden der
            Mitglieder zu gewährleisten.
          </p>
          <img
          title="Vorstand"
            src={require("../images/Vorstand-01.webp")}
            alt="Bild des Vorstandvorsitzenden Hannes Lokatis"
            style={{
              width: 350,
              resizeMode: "contain",
            }}
          /> */}
        </Box>
      </div>
    </HelmetProvider>
  );
};

export default Anbauvereinigung;
